import { useWalletConnection } from "../../utility/useWalletConnection";
import { formatAddress } from "../../utility/Utils";
import chooseNetwork from "../../assets/img/choose-network.png";

//TODO: Testnet testleri tamamlandıktan sonra network seçim ve izinleri ayarlanacak
const ChooseNetwork = () => {
  const { isConnected, address, connect, disConnect, open } =
    useWalletConnection();

  //https://docs.reown.com/appkit/react/core/hooks
  return (
    <div style={{cursor: 'pointer'}} title="Choose Network">
      <img
        onClick={() => open({ view: "Networks" })}
        src={chooseNetwork}
        style={{
          border: '2px solid #985',
          width: "44px",
          padding: "5px",
        }}
        alt="choose network"
      />
    </div>
  );
};

export default ChooseNetwork;
