// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-pin {
    width: 32px;
    margin-top: 4px;
    /* background-image: url("../../assets/img/pin.png"); */
}

.map-pin-label {
    padding: 4px 6px;
    border-radius: 4px;
    margin: 0;
    font-weight: bold;
    background-color: #212121;
}

.map-info-window {
    font-size: 10pt;
}

.map-info-window input {
    font-size: 10pt;
}

.map-info-window button {
    font-size: 10pt;
}`, "",{"version":3,"sources":["webpack://./src/components/google-maps/GoogleMaps.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,uDAAuD;AAC3D;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,SAAS;IACT,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".map-pin {\n    width: 32px;\n    margin-top: 4px;\n    /* background-image: url(\"../../assets/img/pin.png\"); */\n}\n\n.map-pin-label {\n    padding: 4px 6px;\n    border-radius: 4px;\n    margin: 0;\n    font-weight: bold;\n    background-color: #212121;\n}\n\n.map-info-window {\n    font-size: 10pt;\n}\n\n.map-info-window input {\n    font-size: 10pt;\n}\n\n.map-info-window button {\n    font-size: 10pt;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
