// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  text-align: center;
}
.go-home-button {
  background: #583760;
  padding: 10px 21px;
  border-radius: 5px;
  margin-right: 0;
  text-align: center;
  width: 180px;
  cursor: pointer;
  color: #f6efe3;
}
.title {
  text-align: center;
  padding: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/NotFound/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,aAAa;AACf","sourcesContent":["p {\n  text-align: center;\n}\n.go-home-button {\n  background: #583760;\n  padding: 10px 21px;\n  border-radius: 5px;\n  margin-right: 0;\n  text-align: center;\n  width: 180px;\n  cursor: pointer;\n  color: #f6efe3;\n}\n.title {\n  text-align: center;\n  padding: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
