// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cuzdan {
  background: #583760;
  padding: 10px 21px;
  border-radius: 5px;
  margin-right: 0;
  text-align: center;
  width: 180px;
  cursor: pointer;
  color: #f6efe3;
}
.cuzdan:hover {
  background-color: #fe724e;
  border: 1px solid #311438;
}
.cuzdan img {
  margin-right: 5px;
  width: 40px;
}
.cuzdan span {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/connect-button/ConnectButton.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;AACA;EACE,iBAAiB;EACjB,WAAW;AACb;AACA;EACE,WAAW;AACb","sourcesContent":[".cuzdan {\n  background: #583760;\n  padding: 10px 21px;\n  border-radius: 5px;\n  margin-right: 0;\n  text-align: center;\n  width: 180px;\n  cursor: pointer;\n  color: #f6efe3;\n}\n.cuzdan:hover {\n  background-color: #fe724e;\n  border: 1px solid #311438;\n}\n.cuzdan img {\n  margin-right: 5px;\n  width: 40px;\n}\n.cuzdan span {\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
