const PageLoading = ({ pageLoadingMsg }) => {
  return (
    <div className={"loader"}>
      <div className={"prtitle"}>
        <div className="spinner-grow" />
        {pageLoadingMsg && pageLoadingMsg}
        <br />
        and wait until the process is completed.
      </div>
    </div>
  );
};

export default PageLoading;
