import React, { useEffect, useState } from "react";

const Countdown = ({ status, startTime, endTime }) => {
  const timestampNow = Math.floor(Date.now() / 1000);
  // const timestampEnd = Math.floor(Date.now() / 1000) + 86410; //test
  let { initialSeconds = Number(endTime - timestampNow) } = "props";
  const [seconds, setSeconds] = useState(
    initialSeconds <= 0 ? 0 : initialSeconds
  );

  const displayTime = () => {
    return (
      <>
        {!isNaN(seconds) && seconds > 0
          ? Math.floor(seconds / (60 * 60 * 24)) > 0
            ? Math.floor(seconds / (60 * 60 * 24)) === 1
              ? `${Math.floor(seconds / (60 * 60 * 24))} day`
              : `${Math.floor(seconds / (60 * 60 * 24))} days`
            : `${Math.floor(
                (seconds % (60 * 60 * 24)) / (60 * 60)
              )} : ${Math.floor((seconds % (60 * 60)) / 60)} : ${Math.floor(
                seconds % 60
              )}`
          : "END"}
      </>
    );
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds <= 0) {
        clearInterval(countdown);
        return;
      }
      setSeconds(seconds - 1);
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, [seconds, endTime]);

  return (
    <>
      {timestampNow < startTime
        ? status === 2 || status === 3
          ? "Not start"
          : "Not start"
        : null}
      {timestampNow >= startTime && timestampNow <= endTime
        ? status === 2 || status === 3
          ? displayTime()
          : "invalid status"
        : null}
      {timestampNow > endTime
        ? status === 2 || status === 3
          ? "END"
          : "END"
        : null}
    </>
  );
};

export default Countdown;
