import React, { useRef, useEffect, useState } from "react";
import * as Cesium from "cesium";
import RedPin from "../../assets/img/pin-red.png";
import circle from "../../assets/svg/circle.svg";

const CesiumMap = React.forwardRef((props, ref) => {
  const [loadingMap, setLoadingMap] = useState(true);
  const cesiumContainer = useRef(null);
  const viewer = useRef(null);
  Cesium.Ion.defaultAccessToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxNTlhNWYxNS0xYzUxLTRiMGUtYTJmNS1kYjFhOTlhY2MxMzAiLCJpZCI6MTg5ODMwLCJpYXQiOjE3MDUzMjQ1NjF9.zBgOmBYbCpgW3c67IFWrVQAZAl30oakUCBjUzOtB1o8";

  useEffect(() => {
    const bingMapsAerialWithLabels = Cesium.ImageryLayer.fromProviderAsync(
      Cesium.IonImageryProvider.fromAssetId(3)
    );

    if (props.poolCount === null) {
      //loading
      setLoadingMap(true);
    } else if (props.myLng && props.myLat && props.poolCount < 1) {
      {
        viewer.current = new Cesium.Viewer(cesiumContainer.current, {
          shouldAnimate: true,
          baseLayer: bingMapsAerialWithLabels,
          baseLayerPicker: false,
          infoBox: false,
        });
        const initialPosition = Cesium.Cartesian3.fromDegrees(
          props.myLng,
          props.myLat,
          props.defDistance
        );
        viewer.current.camera.setView({ destination: initialPosition });
      }
      setLoadingMap(false);
    } else if (
      props.myLng &&
      props.myLat &&
      props.poolCount > 0 &&
      props.pools.length > 0
    ) {
      {
        viewer.current = new Cesium.Viewer(cesiumContainer.current, {
          shouldAnimate: true,
          baseLayer: bingMapsAerialWithLabels,
          baseLayerPicker: false,
          infoBox: false,
        });
        const initialPosition = Cesium.Cartesian3.fromDegrees(
          props.myLng,
          props.myLat,
          props.defDistance
        );
        viewer.current.camera.setView({ destination: initialPosition });
      }

      const scene = viewer.current.scene;
      const handler = new Cesium.ScreenSpaceEventHandler(scene.canvas);

      handler.setInputAction((event) => {
        const marker = viewer.current.scene.pick(event.position);

        if (Cesium.defined(marker)) {
          //Cluster edilmiş pinlerde id yok tıklandığında hatayı önlemek için
          if (marker.id) {
            const pool = marker.id.pool;
            console.log(pool);
            props.handleFlyToCity({
              poolId: pool.poolId,
              name: pool.name,
              img: pool.img,
              createDate: pool.createDate,
              joinDay: pool.joinDay,
              lockDay: pool.lockDay,
              totalPool: pool.totalPool,
              oneTicketOfToken: pool.oneTicketOfToken,
              maxTicketCount: pool.maxTicketCount,
              minTicketCount: pool.minTicketCount,
              lockTokenAddress: pool.lockTokenAddress,
              prizeTokenAddress: pool.prizeTokenAddress,
              lockTokenSymbol: pool.lockTokenSymbol,
              prizeTokenSymbol: pool.prizeTokenSymbol,
              totalPrizeToken: pool.totalPrizeToken,
              status: pool.status,
              lat: pool.lat,
              long: pool.long,
              zoom: pool.zoom,
              distance: pool.distance,
              callbackMaxTicket: pool.callbackMaxTicket,
              poolTicketLimit: pool.poolTicketLimit,
            });
            console.log(marker.isCluster);
          } else {
            const position = marker.primitive._actualPosition;

            if (position) {
              const cartographic =
                Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);

              const longitude = Cesium.Math.toDegrees(cartographic.longitude);
              const latitude = Cesium.Math.toDegrees(cartographic.latitude);
              const height = cartographic.height;

              viewer.current.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(
                  longitude,
                  latitude,
                  800000
                ),
                duration: 1,
              });
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      //cluster

      const poolDataSource = new Cesium.CustomDataSource("poolDataSource");

      poolDataSource.clustering.enabled = true;

      poolDataSource.clustering.minimumClusterSize = 2;

      poolDataSource.clustering.pixelRange = 5;

      poolDataSource.clustering.clusterEvent.addEventListener(function (
        clusteredEntities,
        cluster
      ) {
        const pinBuilder = new Cesium.PinBuilder();

        const pinImages = {
          pin50: pinBuilder.fromText("10 +", Cesium.Color.RED, 48).toDataURL(),
          pin40: pinBuilder
            .fromText("8 +", Cesium.Color.ORANGE, 48)
            .toDataURL(),
          pin30: pinBuilder
            .fromText("6 +", Cesium.Color.YELLOW, 48)
            .toDataURL(),
          pin20: pinBuilder.fromText("4 +", Cesium.Color.GREEN, 48).toDataURL(),
          pin10: pinBuilder.fromText("2 +", Cesium.Color.BLUE, 48).toDataURL(),
        };

        cluster.billboard.show = true;

        cluster.label.show = false;
        const entityCount = clusteredEntities.length;

        if (entityCount >= 10) {
          cluster.billboard.image = pinImages.pin50;
        } else if (entityCount >= 8) {
          cluster.billboard.image = pinImages.pin40;
        } else if (entityCount >= 6) {
          cluster.billboard.image = pinImages.pin30;
        } else if (entityCount >= 4) {
          cluster.billboard.image = pinImages.pin20;
        } else if (entityCount >= 2) {
          cluster.billboard.image = pinImages.pin10;
        } else {
          cluster.billboard.image = RedPin;
        }
      });

      function addMultipleBillboards(pool) {
        if (pool.poolId !== 0) {
          // Cluster için verileri Custom Data Source olarak ayarladım
          poolDataSource.entities.add({
            pool,
            position: Cesium.Cartesian3.fromDegrees(
              Number(pool.long),
              Number(pool.lat)
            ),
            billboard: {
              image: RedPin,
              scale: 0.6,
            },
            // label: {
            //   text: pool.name,
            // },
          });
        }
      }
      viewer.current.dataSources.add(poolDataSource);

      props.pools.forEach((pool) => {
        addMultipleBillboards(pool);
      });

      setLoadingMap(false);
    }

    // ref'i dışa aktar
    ref.current = {
      flyToCity: (latitude, longitude, duration, zoom) => {
        viewer.current &&
          viewer.current.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(
              longitude,
              latitude,
              zoom
            ),
            duration: duration,
          });
      },
    };

    return () => {
      if (viewer.current) {
        viewer.current.destroy();
      }
    };
  }, [ref, props.poolCount, props.pools, props.myLng, props.myLat]);

  return (
    <div>
      {loadingMap && (
        <h3 style={{ textAlign: "center", marginTop: "20%" }}>
          <span className="spinner-border spinner-border"></span>
          <span style={{ marginLeft: "5px" }}>LOADING MAP & POOLS</span>
        </h3>
      )}
      <div
        className={"cmap"}
        ref={cesiumContainer}
        style={{ width: "115%", height: "89.5vh" }}
      />
    </div>
  );
});

export default CesiumMap;
