import { BrowserProvider, JsonRpcProvider, Contract, ethers } from "ethers";
import {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import {
  useWeb3Modal,
  useDisconnect,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  useWeb3ModalError,
  useSwitchNetwork,
  useWalletInfo,
} from "@web3modal/ethers/react";

import Ticket from "../abis/Ticket.json";
import VRF from "../abis/VRFCoordinatorV2_5Mock.json";

const initialSignerVars = {
  provider: null,
  signer: null,
  contract: null,
  isDeployer: false,
  isAdmin: false,
};

const initialUnSignerVars = {
  provider: null,
  contract: null,
  ONEDAY: 0,
  PRECISION_BASIS: 0,
  AWAITING_ASSIGN: 0,
  AWAITING_DRAW: 0,
};

const WalletContext = createContext();

export const WalletContextProvider = ({ children }) => {
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { error } = useWeb3ModalError();
  const { switchNetwork } = useSwitchNetwork();
  const { walletInfo } = useWalletInfo();

  const [errorMessage, setErrorMessage] = useState(error && error);
  const clearError = () => setErrorMessage("");
  const [signerVars, setSignerVars] = useState(initialSignerVars);
  const [unSignerVars, setUnSignerVars] = useState(initialUnSignerVars);
  const [mockVRF, setMockVRF] = useState(null);

  const connectContract = async () => {
    try {
      // set unsigner Contract
      const jsonRpcProvider = new JsonRpcProvider(
        `${process.env.REACT_APP_NETWORKRPC}`
      );
      const _unSignerContract = new Contract(
        process.env.REACT_APP_CONTRACT_ADDRESS,
        Ticket.abi,
        jsonRpcProvider
      );

      if (_unSignerContract) {
        setUnSignerVars({
          provider: jsonRpcProvider,
          contract: _unSignerContract,
          ONEDAY: Number(await _unSignerContract.ONEDAY()),
          PRECISION_BASIS: Number(await _unSignerContract.PRECISION_BASIS()),
          AWAITING_ASSIGN: Number(await _unSignerContract.AWAITING_ASSIGN()),
          AWAITING_DRAW: Number(await _unSignerContract.AWAITING_DRAW()),
        });
      }

      // set Contract
      if (isConnected && address) {
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const _contract = new Contract(
          process.env.REACT_APP_CONTRACT_ADDRESS,
          Ticket.abi,
          signer
        );

        if (chainId === 31337) {
          const _vrf = new Contract(
            process.env.REACT_APP_VRFCONTRACT_ADDRESS,
            VRF.abi,
            signer
          );

          if (_vrf) {
            setMockVRF(_vrf);
          }
        }

        if (signer && _contract) {
          setSignerVars({
            provider,
            signer,
            contract: _contract,
            isDeployer:
              (await _contract.deployer()).toLowerCase() ===
              address.toLowerCase()
                ? true
                : false,
            isAdmin: await _contract.admins(address),
          });
        }
      }

      clearError();
    } catch (err) {
      console.log("useWallet:", err);
      setErrorMessage(err);
    }
  };

  const _loadWallet = useCallback(async () => {
    if (chainId !== Number(process.env.REACT_APP_NETWORKID)) {
      await switchNetwork(Number(process.env.REACT_APP_NETWORKID))
        .then(() => {
          connectContract();
        })
        .catch((err) => {
          console.log(err.message);
          setErrorMessage(err.message);
          disConnect();
        });
    } else {
      connectContract();
    }
  }, [address, chainId, isConnected]);

  const connect = async () => {
    clearError();
    open();
  };

  const disConnect = async () => {
    clearError();
    disconnect();
    // setContractInfo(initialContractInfo);
    setSignerVars(initialSignerVars);
  };

  useEffect(() => {
    if (error) {
      console.log("useWeb3ModalError:", error);
      setErrorMessage(`useWeb3ModalError: ${error}`);
    }
    _loadWallet();
  }, [_loadWallet]);

  return (
    <WalletContext.Provider
      value={{
        isConnected,
        connectedWallet: walletInfo !== undefined && walletInfo.name,
        address,
        chainId,
        signerVars,
        unSignerVars,
        mockVRF,
        // error: !!errorMessage,
        errorMessage,
        clearError,
        open,
        connect,
        disConnect,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWalletConnection = () => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error(
      'useWalletConnection must be used within a "WalletContextProvider"'
    );
  }
  return context;
};
