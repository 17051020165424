import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ConnectButton from "../connect-button/ConnectButton";
import { useWalletConnection } from "../../utility/useWalletConnection";
//css
import "./Header.css";
//images
import logo from "../../assets/img/logo.svg";
import pp from "../../assets/img/profil-photo.png";
import ChooseNetwork from "../connect-button/ChooseNetwork";

const Header = () => {
  const { search, pathname } = useLocation();
  const {
    isConnected,
    connectedWallet,
    address,
    signerVars,
    unSignerVars,
    mockVRF,
  } = useWalletConnection();

  const fulfillRandomWords = async () => {
    if (sessionStorage.getItem("lastRequestSent")) {
      const requestId = JSON.parse(
        sessionStorage.getItem("lastRequestSent")
      ).requestId;
      const numberOfWords = JSON.parse(
        sessionStorage.getItem("lastRequestSent")
      ).numberOfWords;

      let randomWords = [];
      for (let i = 0; i < numberOfWords; i++) {
        const randomNum =
          Math.floor(Math.random() * (999999 - 111111)) + 111111;
        randomWords.push(randomNum);
      }

      const estimateGas = Number(
        await mockVRF.fulfillRandomWords.estimateGas(
          requestId,
          process.env.REACT_APP_CONTRACT_ADDRESS
        )
      );
      console.log("estimateGas:", estimateGas);
      mockVRF
        .fulfillRandomWords(requestId, process.env.REACT_APP_CONTRACT_ADDRESS, {
          gasLimit: estimateGas * 2,
        })
        .then(async (result) => {
          const receipt = await result.wait();
          console.log("receipt (fulfillRandomWords)", receipt);
          sessionStorage.removeItem("lastRequestSent");
        })
        .catch((err) => {
          console.error("fulfillRandomWords Error:", err.message);
        });
    } else {
      console.log("Not found sessionStorage (lastRequestSent)");
    }
  };

  // Contract Events (mock)
  useEffect(() => {
    if (Number(process.env.REACT_APP_NETWORKID) === 31337) {
      if (unSignerVars.contract) {
        unSignerVars.contract.on(
          "RequestSent",
          async (requestId, numberOfWords) => {
            console.log("#RequestSent event was emmited");
            console.log(">>>", Number(requestId), Number(numberOfWords));
            sessionStorage.setItem(
              "lastRequestSent",
              JSON.stringify({
                requestId: Number(requestId),
                numberOfWords: Number(numberOfWords),
              })
            );
          }
        );

        unSignerVars.contract.on(
          "RequestFulfilled",
          async (requestId, reqType, poolId, user, numberOfWords) => {
            console.log("#RequestFulfilled event was emmited");
            console.log(
              ">>>",
              Number(requestId),
              Number(reqType),
              Number(poolId),
              user,
              Number(numberOfWords)
            );
            sessionStorage.setItem(
              "lastRequestFulfilled",
              JSON.stringify({
                requestId: Number(requestId),
                reqType: Number(reqType),
                poolId: Number(poolId),
                user: user,
                numberOfWords: Number(numberOfWords),
              })
            );
          }
        );
      }
    }
  }, [unSignerVars]);

  return (
    <div className="header">
      <Link
        to={"/"}
        className="left-area"
        onClick={() => {
          pathname.toLowerCase() !== "/panel" && window.location.reload();
        }}
      >
        <img src={logo} alt="" />
        <img src="img/logo.png" alt="" />
        <span>VESMAP</span>
      </Link>
      <div className="right-area">
        {Number(process.env.REACT_APP_NETWORKID) === 31337 && (
          <nav>
            <Link to={search} onClick={() => fulfillRandomWords()}>
              getRandom
            </Link>
          </nav>
        )}
        <nav>
          <Link
            target={"_blank"}
            to={`${process.env.REACT_APP_NETWORKSCAN}address/${process.env.REACT_APP_CONTRACT_ADDRESS}`}
          >
            Contract
          </Link>
        </nav>
        <nav>
          <ChooseNetwork />
        </nav>
        <div className="cuzdan-dropdown">
          <ConnectButton />
          <div className="cuzdan-dropdown-content">
            {signerVars.isDeployer ? (
              <>
                <p>Hi! Deployer 👋</p>
                <p>
                  <Link to={"/panel"}>Go to Panel</Link>
                </p>
              </>
            ) : signerVars.isAdmin ? (
              <>
                <p>Hi! Admin 👋</p>
                <p>
                  <Link to={"/panel"}>Go to Admin Panel</Link>
                </p>
              </>
            ) : isConnected ? (
              <>
                <p>Hi! Welcome 👋</p>
              </>
            ) : (
              <>
                <p>Please connect your wallet to join the pools.</p>
              </>
            )}
          </div>
        </div>
        <div className="profil-img">
          <img src={pp} alt="user profile" />
        </div>
      </div>
    </div>
  );
};

export default Header;
