import React, { useState, useEffect } from "react";

const PoolListFilter = ({ pools, onFilterChange, uniqueKey }) => {
  const [statusFilter, setStatusFilter] = useState("all");
  const [sortOrder, setSortOrder] = useState("A-Z");

  const handleFilterChange = () => {
    const filteredPools = pools
      .filter((pool) => {
        if (statusFilter === "all") return true;
        return pool.status === Number(statusFilter);
      })
      .sort((x, y) => {
        if (sortOrder === "A-Z") {
          const nameComparison = x.name.localeCompare(y.name);
          if (nameComparison !== 0) return nameComparison;
        }
        if (sortOrder === "Z-A") {
          const nameComparison = y.name.localeCompare(x.name);
          if (nameComparison !== 0) return nameComparison;
        }
        if (sortOrder === "Low to High") {
          return x.maxTicketCount - y.maxTicketCount;
        }
        if (sortOrder === "High to Low") {
          return y.maxTicketCount - x.maxTicketCount;
        }

        return 0;
      });

    onFilterChange(filteredPools);
  };

  useEffect(() => {
    handleFilterChange();
  }, [statusFilter, sortOrder]);

  return (
    <div className="border-bottom border-dark mb-2">
      <div
        className="btn-group inline-flex mb-2 w-100 flex-wrap"
        role="group"
        aria-label="Basic radio toggle button group"
      >
        <input
          type="radio"
          id={`status-all-${uniqueKey}`}
          value="all"
          checked={statusFilter === "all"}
          onChange={() => setStatusFilter("all")}
          className="btn-check"
        />
        <label
          className="btn btn-light btn-sm btn-sm"
          htmlFor={`status-all-${uniqueKey}`}
        >
          {" "}
          All{" "}
        </label>

        {/* <input
          type="radio"
          id={`status-deactive-${uniqueKey}`}
          value="1"
          checked={statusFilter === "1"}
          onChange={() => setStatusFilter("1")}
          className="btn-check"
        />
        <label
          className="btn btn-light btn-sm btn-sm"
          htmlFor={`status-deactive-${uniqueKey}`}
        >
          {" "}
          Deactive{" "}
        </label> */}

        <input
          type="radio"
          id={`status-join-${uniqueKey}`}
          value="2"
          checked={statusFilter === "2"}
          onChange={() => setStatusFilter("2")}
          className="btn-check"
        />
        <label
          className="btn btn-light btn-sm btn-sm"
          htmlFor={`status-join-${uniqueKey}`}
        >
          {" "}
          Join{" "}
        </label>

        <input
          type="radio"
          id={`status-locked-${uniqueKey}`}
          value="3"
          checked={statusFilter === "3"}
          onChange={() => setStatusFilter("3")}
          className="btn-check"
        />
        <label
          className="btn btn-light btn-sm btn-sm"
          htmlFor={`status-locked-${uniqueKey}`}
        >
          {" "}
          Locked{" "}
        </label>

        <input
          type="radio"
          id={`status-finished-${uniqueKey}`}
          value="6"
          checked={statusFilter === "6"}
          onChange={() => setStatusFilter("6")}
          className="btn-check"
        />
        <label
          className="btn btn-light btn-sm"
          htmlFor={`status-finished-${uniqueKey}`}
        >
          {" "}
          Finished{" "}
        </label>

        <input
          type="radio"
          id={`status-claim-${uniqueKey}`}
          value="4"
          checked={statusFilter === "4"}
          onChange={() => setStatusFilter("4")}
          className="btn-check"
        />
        <label
          className="btn btn-light btn-sm"
          htmlFor={`status-claim-${uniqueKey}`}
        >
          {" "}
          Claim{" "}
        </label>
      </div>

      <div className=" mb-2" style={{ width: "100%" }}>
        <div className="d-flex justify-content-between">
          <select
            style={{ outline: "none", boxShadow: "none" }}
            className="form-select form-select-sm border-0 "
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value="A-Z">A-Z</option>
            <option value="Z-A">Z-A</option>
            <option value="Low to High">Low to High</option>
            <option value="High to Low">High to Low</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default PoolListFilter;
