import { defaultConfig } from "@web3modal/ethers/react";

export const projectId = process.env.REACT_APP_WC_PROJECTID;
if (!projectId) throw new Error("Project ID is not defined");

//Mainnets
export const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io/",
  rpcUrl: "https://cloudflare-eth.com",
};
export const arbitrumOne = {
  chainId: 42161,
  name: "Arbitrum One Mainnet",
  currency: "ETH",
  explorerUrl: "https://arbiscan.io/",
  rpcUrl: "https://arb1.arbitrum.io/rpc",
};
export const polygon = {
  chainId: 137,
  name: "Polygon Mainnet",
  currency: "POL",
  explorerUrl: "https://polygonscan.com/",
  rpcUrl: "https://polygon-rpc.com/",
};
export const bsc = {
  chainId: 56,
  name: "BNB Smart Chain Mainnet",
  currency: "BNB",
  explorerUrl: "https://bscscan.com/",
  rpcUrl: "https://bsc-dataseed1.binance.org/",
};

//Testnets
export const localhost = {
  chainId: 31337,
  name: "localhost",
  currency: "LocalETH",
  explorerUrl: "http://localhost:8545/",
  rpcUrl: "http://127.0.0.1:8545/",
};
export const ethSepolia = {
  chainId: 11155111,
  name: "Sepolia",
  currency: "SepoliaETH",
  explorerUrl: "https://sepolia.etherscan.io/",
  rpcUrl: "https://ethereum-sepolia-rpc.publicnode.com",
};
export const arbSepolia = {
  chainId: 421614,
  name: "Arbitrum Sepolia Testnet",
  currency: "ETH",
  explorerUrl: "https://sepolia.arbiscan.io/",
  rpcUrl: "https://sepolia-rollup.arbitrum.io/rpc",
};
export const polygonAmoy = {
  chainId: 80002,
  name: "Polygon Amoy Testnet",
  currency: "POL",
  explorerUrl: "https://amoy.polygonscan.com/",
  rpcUrl: "https://rpc-amoy.polygon.technology",
};
export const bscTestnet = {
  chainId: 97,
  name: "BNB Smart Chain Testnet",
  currency: "BNB",
  explorerUrl: "https://testnet.bscscan.com/",
  rpcUrl: "https://data-seed-prebsc-1-s1.bnbchain.org:8545",
};

const metadata = {
  name: "Vesmap",
  description: "Vesmap is a product of vestate",
  url: "https://vesmap.com", // origin must match your domain & subdomain
  icons: ["https://vestate.fund/assets/images/favicon.png"],
};

export const ethersConfig = defaultConfig({
  metadata,
});
