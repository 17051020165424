import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useWalletConnection } from "../../utility/useWalletConnection";
import { useData } from "../../utility/useData";
import Header from "../../components/header/Header";
import LeftMenu from "../../components/left-menu/LeftMenu";
import CesiumMap from "../../components/cesium-map/CesiumMap";
import Stake from "../../components/stake/Stake";
//images
import Mobile from "../../assets/img/mobile2.jpg";
import Globe from "../../assets/img/globe.png";

const Home = ({ myLocation }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const { isConnected, address, signerVars, unSignerVars, errorMessage } =
    useWalletConnection();

  const {
    initalMessage,
    initalData,
    showPoolDetail,
    poolData,
    togglePoolData,
    openData,
    closeData,
    updatePoolData,
  } = useData();

  const [poolCount, setPoolCount] = useState(null);
  const [pools, setPools] = useState([]);

  const sideBarStatus = sessionStorage.getItem("sidebar");
  const [sideBar, setSideBar] = useState(
    sideBarStatus ? JSON.parse(sideBarStatus) : true
  );
  const cesiumMapRef = useRef(null);

  const handleFlyToCity = (flyToPoolData) => {
    closeData();
    let duration = Number(flyToPoolData.zoom) * 1000;

    cesiumMapRef.current.flyToCity(
      Number(flyToPoolData.lat),
      Number(flyToPoolData.long),
      Number(flyToPoolData.zoom),
      Number(flyToPoolData.distance)
    );

    updatePoolData(flyToPoolData);
    if (flyToPoolData.poolId !== 0) {
      setTimeout(() => {
        openData(flyToPoolData);
      }, duration);
    }
  };

  const toggleSideBar = () => {
    setSideBar((prevState) => !prevState);
    sessionStorage.setItem("sidebar", !sideBar);
  };

  useEffect(() => {
    if (signerVars && signerVars.isDeployer) {
      navigate("/panel");
    }
    closeData();
  }, [signerVars]);

  return (
    <>
      <div className="pop">
        <img src={Mobile} />
      </div>
      <div className="vestbody">
        <Header />
        <div className="content-wrapper">
          <img
            className={sideBar ? "globe showglobe" : "globe hideglobe"}
            src={Globe}
            onClick={() =>
              handleFlyToCity({
                poolId: 0,
                name: "My Location",
                img: null,
                createDate: 0,
                joinDay: 0,
                lockDay: 0,
                totalPool: 0,
                oneTicketOfToken: 0,
                maxTicketCount: 0,
                minTicketCount: 0,
                lockTokenAddress: null,
                prizeTokenAddress: null,
                lockTokenSymbol: null,
                prizeTokenSymbol: null,
                totalPrizeToken: 0,
                status: 0,
                lat: myLocation.lat,
                long: myLocation.lng,
                zoom: 3,
                distance: 30000000,
              })
            }
          />
          <Link
            href={search}
            className={
              sideBar
                ? "arr-right-link hidesarrow"
                : "arr-right-link showsarrow"
            }
            title={sideBar ? "Hide Sidebar" : "Show Sidebar"}
            onClick={() => toggleSideBar()}
          />
          <div className={"right-map-area "}>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n     #toolbar {\n       position: absolute;\n       top:0;\n     }\n     ",
              }}
            />
            <div id="cesiumContainer" className="fullSize" />
            <div id="loadingOverlay">
              {showPoolDetail && <Stake />}
              <CesiumMap
                className={"csmp"}
                ref={cesiumMapRef}
                myLat={myLocation.lat}
                myLng={myLocation.lng}
                defDistance={30000000}
                handleFlyToCity={handleFlyToCity}
                poolCount={poolCount}
                pools={pools}
              />
            </div>
          </div>
          <div
            className={
              sideBar ? "left-menu-area shows" : "left-menu-area hides"
            }
          >
            <Link
              to={search}
              className="arr-right-link"
              onClick={() => toggleSideBar()}
            />
            <LeftMenu
              myLat={myLocation.lat}
              myLng={myLocation.lng}
              defDistance={30000000}
              handleFlyToCity={handleFlyToCity}
              poolCount={poolCount}
              setPoolCount={setPoolCount}
              pools={pools}
              setPools={setPools}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
