import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useWalletConnection } from "./utility/useWalletConnection";
import { useData } from "./utility/useData";
import Home from "./pages/Home";
import Panel from "./pages/Panel";
//css
import "./App.css";
import NotFound from "./pages/NotFound";

const App = () => {
  const { isConnected, address, contractInfo, errorMessage } =
    useWalletConnection();

  const {
    showPoolDetail,
    poolData,
    togglePoolData,
    openData,
    closeData,
    updatePoolData,
  } = useData();

  const [myLocation, setMyLocation] = useState({
    state: false,
    lat: 32.938058,
    lng: 21.714991,
    acc: 0,
  });

  //Notice: konum izni geçici olarak kapatıldı.
  // const options = { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 };
  // const success = (pos) => {
  //   setMyLocation({
  //     state: true,
  //     lat: pos.coords.latitude,
  //     lng: pos.coords.longitude,
  //     acc: pos.coords.accuracy,
  //   });
  //   const crd = pos.coords;
  //   console.log("Your current position is:");
  //   console.log(`Latitude : ${crd.latitude}`);
  //   console.log(`Longitude: ${crd.longitude}`);
  //   console.log(`More or less ${crd.accuracy} meters.`);
  // };

  // const errors = (err) => {
  //   console.warn(`ERROR(${err.code}): ${err.message}`);
  //   setMyLocation({
  //     state: false,
  //     lat: 48.225724,
  //     lng: 16.385819,
  //     acc: 0,
  //   });
  // };

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.permissions
  //       .query({ name: "geolocation" })
  //       .then(function (result) {
  //         console.log(result)
  //         if (result.state === "granted") {
  //           navigator.geolocation.getCurrentPosition(success, errors, options);
  //         } else if (result.state === "prompt") {
  //           navigator.geolocation.getCurrentPosition(success, errors, options);
  //         } else if (result.state === "denied") {
  //           console.log(">> location permission denied");
  //         }
  //       });
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //   }
  // }, []);

  return (
    <Routes>
      <Route path="/" element={<Home myLocation={myLocation} />} />
      <Route path="/panel" element={<Panel myLocation={myLocation} />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
