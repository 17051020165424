import { useWalletConnection } from "../../utility/useWalletConnection";
import { formatAddress } from "../../utility/Utils";
//css
import "./ConnectButton.css";

const ConnectButton = () => {
  const { isConnected, address, connect, disConnect } = useWalletConnection();

  return (
    <>
      {isConnected ? (
        <div className="cuzdan" onClick={() => disConnect()}>
          {formatAddress(address)}
        </div>
      ) : (
        <div className="cuzdan" onClick={() => connect()}>
          Connect Wallet
        </div>
      )}
    </>
  );
};

export default ConnectButton;
