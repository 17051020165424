// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  text-align: center;
  padding: 18px;
}
.button {
  background: #583760;
  padding: 10px 21px;
  border-radius: 5px;
  text-align: center;
  width: 180px;
  cursor: pointer;
  color: #f6efe3;
}
.padding-2 {
  padding: 2%;
}
input {
  margin: 2px 0px !important;
}
label {
  margin-bottom: 0 !important;
  margin-top: 2px !important;
}
.section-functions {
  border: 1px solid #583760;
  border-radius: 12px;
  padding: 6px 8px;
  margin: 4px 0;
  display: none;
}
.col{
  align-content: center !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.list-group-item.active {
  background-color: #583760 !important;
  border-color: #38233d !important;
}
.list-group-item.active:hover {
  background-color: #fe724e !important;
  border-color: #311438 !important;
}
.btn-dark {
  --bs-btn-bg: #583760 !important;
  --bs-btn-border-color: #38233d !important;
  --bs-btn-hover-bg: #fe724e !important;
  --bs-btn-hover-border-color: #311438 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Panel/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,cAAc;AAChB;AACA;EACE,WAAW;AACb;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,2BAA2B;EAC3B,0BAA0B;AAC5B;AACA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,aAAa;AACf;AACA;EACE,gCAAgC;AAClC;;AAEA,UAAU;AACV;EACE,WAAW;AACb;;AAEA,UAAU;AACV;EACE,gBAAgB;AAClB;;AAEA,WAAW;AACX;EACE,gBAAgB;AAClB;;AAEA,oBAAoB;AACpB;EACE,gBAAgB;AAClB;AACA;EACE,oCAAoC;EACpC,gCAAgC;AAClC;AACA;EACE,oCAAoC;EACpC,gCAAgC;AAClC;AACA;EACE,+BAA+B;EAC/B,yCAAyC;EACzC,qCAAqC;EACrC,+CAA+C;AACjD","sourcesContent":[".title {\n  text-align: center;\n  padding: 18px;\n}\n.button {\n  background: #583760;\n  padding: 10px 21px;\n  border-radius: 5px;\n  text-align: center;\n  width: 180px;\n  cursor: pointer;\n  color: #f6efe3;\n}\n.padding-2 {\n  padding: 2%;\n}\ninput {\n  margin: 2px 0px !important;\n}\nlabel {\n  margin-bottom: 0 !important;\n  margin-top: 2px !important;\n}\n.section-functions {\n  border: 1px solid #583760;\n  border-radius: 12px;\n  padding: 6px 8px;\n  margin: 4px 0;\n  display: none;\n}\n.col{\n  align-content: center !important;\n}\n\n/* width */\n::-webkit-scrollbar {\n  width: 10px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: #eee; \n}\n \n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #888; \n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #555; \n}\n.list-group-item.active {\n  background-color: #583760 !important;\n  border-color: #38233d !important;\n}\n.list-group-item.active:hover {\n  background-color: #fe724e !important;\n  border-color: #311438 !important;\n}\n.btn-dark {\n  --bs-btn-bg: #583760 !important;\n  --bs-btn-border-color: #38233d !important;\n  --bs-btn-hover-bg: #fe724e !important;\n  --bs-btn-hover-border-color: #311438 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
