import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CloseButton } from "react-bootstrap";
import { useWalletConnection } from "../../utility/useWalletConnection";
import { useData } from "../../utility/useData";
import { formatEther } from "ethers";

const PrizeDetails = ({ onClose }) => {
  const { search } = useLocation();
  const { isConnected, connectedWallet, address, unSignerVars } =
    useWalletConnection();
  const {
    decimals,
    initalResultMsg,
    showPoolDetail,
    poolData,
    setReRender,
    togglePoolData,
    openData,
    closeData,
    updatePoolData,
    statusText,
  } = useData();

  const [isLoading, setIsLoading] = useState(true);

  const [lpwc, setLpwc] = useState(0);
  const [prizes, setPrizes] = useState([]);

  const getPrizes = async () => {
    setIsLoading(true);
    const getInfo = await unSignerVars.contract.getInfo(
      poolData.poolId,
      "0x0000000000000000000000000000000000000000"
    );
    const prizeShareCount = Number(getInfo.prizeShareCount);

    let p = [];
    for (let i = 0; i < prizeShareCount; i++) {
      const poolPrizes = await unSignerVars.contract.poolPrizes(
        poolData.poolId,
        i
      );
      p.push({
        wonPrize: i + 1,
        isWon: poolPrizes[0],
        share: Number(poolPrizes[1]),
      });
    }
    console.log(p);
    setPrizes(p);

    const lpwc = Number(
      await unSignerVars.contract.lastPrizeWinnerCount(poolData.poolId)
    );
    setLpwc(lpwc);
    setIsLoading(false);
  };

  useEffect(() => {
    if (unSignerVars.contract && poolData && poolData.poolId > 0) {
      getPrizes();
    }
  }, [poolData, unSignerVars]);

  return (
    <div className={"loader"}>
      <div
        className={"prtitle"}
        style={{
          width: "80%",
          height: "75%",
          border: "1px solid #000",
          background: "#f6efe3",
          borderRadius: "12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <CloseButton
          style={{ top: "20px", right: "20px", position: "absolute" }}
          onClick={() => onClose()}
        />
        <h1>Prizes in {poolData.name} Pool</h1>
        <div className="container">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                className="spinner-border"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Loading</span>
            </div>
          ) : (
            <div style={{ fontSize: "12pt" }}>
              <p style={{ margin: "0" }}>
                Total Ticket: {poolData.maxTicketCount}
              </p>
              <p style={{ margin: "0" }}>
                Total Prize:{" "}
                {Number(formatEther(poolData.totalPrizeToken)).toFixed(2)} (
                {poolData.prizeTokenSymbol})
              </p>
              <p style={{ margin: "0" }}>
                Last Prize Winner Count: {lpwc} Ticket
              </p>
              <div
                className="row row-cols-auto g-2"
                style={{
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                {prizes.length > 0 &&
                  prizes.map((prize, index) => (
                    <div
                      key={index}
                      className="card"
                      style={{
                        margin: "0",
                        width: "9rem",
                        margin: "4px",
                      }}
                    >
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          {prize.wonPrize}. Winning ticket
                        </li>
                        <li className="list-group-item">
                          % {Math.floor(prize.share / 100)}
                        </li>
                        <li className="list-group-item">
                          {Number(
                            (formatEther(poolData.totalPrizeToken) *
                              prize.share) /
                              10000
                          )}{" "}
                          {poolData.prizeTokenSymbol}
                        </li>
                      </ul>
                    </div>
                  ))}
              </div>
              <p>
                4. prize: will be divided equally among the{" "}
                {lpwc > 0
                  ? lpwc
                  : poolData.maxTicketCount - (prizes.length - 1)}{" "}
                winning tickets (
                {prizes.length > 0 &&
                  Number(
                    (formatEther(poolData.totalPrizeToken) *
                      prizes[prizes.length - 1].share) /
                      (10000 * lpwc)
                  ).toFixed(2)}{" "}
                {poolData.prizeTokenSymbol})
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrizeDetails;
