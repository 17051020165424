import { createContext, useContext, useState } from "react";

const DataContext = createContext();

export const DataContextProvider = ({ children }) => {
  const decimals = 10 ** 18;
  const initalResultMsg = {
    msgType: 0, //1: error, 2: success
    urlTitle: null,
    url: null,
    msg: null,
  };
  const initalData = {
    poolId: 0,
    name: null,
    img: null,
    createDate: 0,
    joinDay: 0,
    lockDay: 0,
    totalPool: 0,
    oneTicketOfToken: 0,
    maxTicketCount: 0,
    minTicketCount: 0,
    lockTokenAddress: null,
    prizeTokenAddress: null,
    lockTokenSymbol: null,
    prizeTokenSymbol: null,
    totalPrizeToken: 0,
    status: 0,
    lat: "41.0082",
    long: "28.9795",
    zoom: 3,
    distance: 30000000,
    callbackMaxTicket: 0,
    poolTicketLimit: 0
  };

  const [showPoolDetail, setShowPoolDetail] = useState(false);
  const [poolData, setPoolData] = useState(initalData);
  const [reRender, setReRender] = useState(false);

  const updatePoolData = (newPoolData) => {
    setPoolData(newPoolData);
  };

  const togglePoolData = () => {
    setShowPoolDetail((prevShowPoolDetail) => !prevShowPoolDetail);
  };

  const openData = () => {
    setShowPoolDetail(true);
  };

  const closeData = () => {
    setShowPoolDetail(false);
  };

  const statusText = (status) => {
    switch (status) {
      case 0:
        return "Invalid";
      case 1:
        return "Deactive";
      case 2:
        return "Join";
      case 3:
        return "Lock";
      case 4:
        return "Claim";
      case 5:
        return "Ended";
    }
  };

  return (
    <DataContext.Provider
      value={{
        reRender,
        decimals,
        initalResultMsg,
        initalData,
        poolData,
        showPoolDetail,
        setReRender,
        togglePoolData,
        openData,
        closeData,
        updatePoolData,
        statusText
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (context === undefined) {
    throw new Error('useData must be used within a "DataContextProvider"');
  }
  return context;
};
