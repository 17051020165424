import Header from "../../components/header/Header";
//css
import "./index.css";
//images
import Mobile from "../../assets/img/mobile2.jpg";

const NotFound = () => {
  return (
    <>
      <div className="pop">
        <img src={Mobile} />
      </div>
      <div className="vestbody">
        <Header />
        <h2 className="title">Not Found Page</h2>
        <p>
          <button
            className="go-home-button"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Go to Home
          </button>
        </p>
      </div>
    </>
  );
};

export default NotFound;
