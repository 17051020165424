import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { WalletContextProvider } from "./utility/useWalletConnection";
import { DataContextProvider } from "./utility/useData";
import { createWeb3Modal } from "@web3modal/ethers/react";
import {
  ethersConfig,
  projectId,
  mainnet,
  ethSepolia,
  arbitrumOne,
  arbSepolia,
  polygon,
  polygonAmoy,
  bsc,
  bscTestnet,
  localhost,
} from "./configs/WalletConnect";

//css
import "bootstrap/dist/css/bootstrap.min.css";

if (!projectId) throw new Error("Project ID is not defined");

createWeb3Modal({
  ethersConfig,
  chains: [
    mainnet,
    arbitrumOne,
    bsc,
    polygon,
    localhost,
    ethSepolia,
    arbSepolia,
    bscTestnet,
    polygonAmoy,
  ],
  projectId,
  enableAnalytics: true,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Suspense>
      <WalletContextProvider>
        <DataContextProvider>
          <App />
        </DataContextProvider>
      </WalletContextProvider>
    </Suspense>
  </BrowserRouter>
);
