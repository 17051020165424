/**
  https://developers.google.com/codelabs/maps-platform/maps-platform-101-react-js#0
  https://developers.google.com/maps/documentation/javascript/examples/event-simple
  https://icons8.com/icons/set/home
**/
import { useEffect, useState, useRef } from "react";
import { useWalletConnection } from "../../utility/useWalletConnection";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import {
  APIProvider,
  Map,
  useMap,
  AdvancedMarker,
  Pin,
  InfoWindow,
} from "@vis.gl/react-google-maps";
//css
import "./GoogleMaps.css";
//img
import pin from "../../assets/img/pin-red.png";

const GoogleMaps = ({
  myLat,
  myLng,
  pools,
  selectedPool,
  setSelectedPool,
  getPoolPrizeShares,
  setSelectedLatLng,
  clickMapSelected,
  setClickMapSelected,
}) => {
  const {
    isConnected,
    connectedWallet,
    address,
    signerVars,
    unSignerVars,
    errorMessage,
  } = useWalletConnection();

  // const [clickMapSelected, setClickMapSelected] = useState(null);
  // const [clickMapSelected, setClickMapSelected] = useState(null);

  return (
    <div style={{ position: "relative", height: "310px" }}>
      <APIProvider
        apiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
        onLoad={() => console.log("Maps API has loaded.")}
      >
        {myLat && myLng && (
          <Map
            mapId="da37f3254c6a6d1c"
            defaultZoom={5}
            defaultCenter={{ lat: myLat, lng: myLng }}
            onCameraChanged={(ev) => {
              // console.log("camera changed:", ev.detail);
            }}
            onClick={(ev) => {
              console.log("clicked: ", ev.detail.latLng);
              setClickMapSelected(ev.detail.latLng);
              setSelectedLatLng({select: true, lat: ev.detail.latLng.lat, lng: ev.detail.latLng.lng});
            }}
          >
            {pools.length > 0 && (
              <PoolMarkers
                pools={pools}
                selectedPool={selectedPool}
                setSelectedPool={setSelectedPool}
                getPoolPrizeShares={getPoolPrizeShares}
                setClickMapSelected={setClickMapSelected}
              />
            )}

            {clickMapSelected && (
              <InfoWindow
                className="map-info-window"
                position={clickMapSelected}
                headerContent="CREATE POOL"
                onClose={() => {
                  console.log("closed NewPool infowindow");
                  setClickMapSelected(null);
                  setSelectedLatLng({select: false, lat: 0, lng: 0});
                }}
                // onCloseClick={() => {
                //   console.log("click closed NewPool infowindow");
                // }}
              >
                <div>
                  <p style={{ margin: 0 }}>lat: {clickMapSelected.lat}</p>
                  <p style={{ margin: 0 }}>lng: {clickMapSelected.lng}</p>
                </div>
              </InfoWindow>
            )}
          </Map>
        )}
      </APIProvider>
    </div>
  );
};

const PoolMarkers = ({ pools, selectedPool, setSelectedPool, getPoolPrizeShares, setClickMapSelected }) => {
  console.log("in poolmarker:", pools);
  const map = useMap();
  const [markers, setMarkers] = useState({});
  const clusterer = useRef(null);

  const poolHandleClick = (ev, pool) => {
    if (!map) return;
    if (!ev.latLng) return;
    if (pool.poolId === 0) {
      map.panTo(ev.latLng);
      setSelectedPool([]);
      getPoolPrizeShares(0);
      return;
    }
    console.log("marker clicked: ", ev.latLng.toString(), pool);
    map.panTo(ev.latLng);
    setSelectedPool([pool]);
    getPoolPrizeShares(pool.poolId);
    setClickMapSelected(null);
  };

  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker, key) => {
    try {
      if (!marker) return; //rerender hatasını çözer
      if (marker && markers[key]) return;
      if (!marker && !markers[key]) return;
      // console.log(marker ,"--", key, "--", markers[key]);

      setMarkers((prev) => {
        if (marker) {
          return { ...prev, [key]: marker };
        } else {
          const newMarkers = { ...prev };
          delete newMarkers[key];
          return newMarkers;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {pools.map((pool) => (
        <AdvancedMarker
          key={pool.poolId}
          position={pool.location}
          ref={(marker) => setMarkerRef(marker, pool.poolId)}
          clickable={true}
          onClick={(ev) => poolHandleClick(ev, pool)}
          title={pool.statusLabel}
        >
          <div style={{ textAlign: "center", color: "#fff" }}>
            {pool.poolId !== 0 && (
              <>
                <p className="map-pin-label">{pool.poolName}</p>
                <img src={pin} className="map-pin" alt={pool.poolName} />
              </>
            )}

            {/* <Pin
              background={"#FBBC04"}
              glyphColor={"#000"}
              borderColor={"#000"}
            /> */}
          </div>
        </AdvancedMarker>
      ))}
      {selectedPool.length > 0 && (
        <InfoWindow
          className="map-info-window"
          position={{
            lat: selectedPool[0].location.lat,
            lng: selectedPool[0].location.lng,
          }}
          headerContent="SELECTED POOL"
          onClose={() => {
            console.log("closed Selected Pool infowindow");
            setSelectedPool([]);
            getPoolPrizeShares(0);
          }}
          // onCloseClick={() => {
          //   console.log("click closed Selected Pool infowindow");
          // }}
        >
          <div>
            <h5 style={{textAlign:'center'}}>{selectedPool[0].poolName}</h5>
            <p style={{ margin: 0, fontWeight: "bold" }}>
              Status: {selectedPool[0].statusLabel}
            </p>
            <p style={{ margin: 0 }}>lat: {selectedPool[0].location.lat}</p>
            <p style={{ margin: 0 }}>lng: {selectedPool[0].location.lng}</p>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default GoogleMaps;
